.aka-page {
    background-color: var(--yellow);
}

.aka-description {
    position: relative;
    width: 100%;
    min-height: 50vh;
    background-color: #f5f2f0;
    padding: 2rem;
    box-sizing: border-box;
}

.aka-description__container {
    position: relative;
    width: 65ch;
    max-width: 100%;
    margin: auto;
}

.aka-description__container a {
    color: var(--yellow);
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.aka-description__container a:hover {
    color: white;
    text-shadow: 0 0 1px white;
}


/* AKA Video */
.aka-vid {
    position: relative;
    width: 600px;
    max-width: 100%;
    margin: auto;
    padding: 2rem;
    box-sizing: border-box;
}


/* Apply Button */
.aka-button__container {
    position: relative;
    width: fit-content;
    height: fit-content;
    padding: 2rem;
    margin: 0 auto;
    box-sizing: border-box;
    gap: 2rem;
}

.aka-button {
    position: relative;
    width: 14rem;
    padding: 1rem 2rem;
    color: var(--black);
    background-color: transparent;
    border: 3px solid var(--black);
    border-radius: 5px;
    font-size: 1.25em;
    cursor: pointer;
    transition: all 0.3s;
    margin: 1rem;
    box-sizing: border-box;
}

.aka-button:hover {
    color: var(--white);
    background-color: var(--black);
    transform: scale(1.05);
    box-shadow: 0px 0px 5px var(--black);
}
