.fundraising-section {
    position: relative;
    width: 100%;
    min-height: 30vh;
    background-color: var(--white);
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.fundraising-container {
    margin: auto;
    width: 400px;
    max-width: 100%;
    text-align: center;
}

.fundraising-section a {
    color: var(--yellow);
    text-decoration: none;
    font-weight: 600;
    display: flex;
    transition: all 0.3s ease-in-out;
}

.fundraising-section a:hover {
    color: white;
    text-shadow: 0 0 1px white;
}