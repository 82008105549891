.involvement-section {
    position: relative;
    margin: 0rem;
    background-color: var(--dark-gray);
    color: white;
    padding: 4rem 0.5rem;
    box-sizing: border-box;
}

.involvement-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/textures/grain.png);
    opacity: 0.1;
}

.involvement-section-text {
    width: 50ch;
    max-width: 95%;
    margin: 0 auto;
    padding: 0 2rem;
    box-sizing: border-box;
    text-align: center;
}

.involvement-section-title {
    position: relative;
    text-align: center;
    font-size: 2rem;
    width: fit-content;
    margin: auto;
}

.involvement-section-title::after {
    content: "";
    position: absolute;
    bottom: -25%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--yellow);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}

.involvement-section-paragraph {
    position: relative;
}

.involvement-section-paragraph a {
    color: var(--yellow);
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.involvement-section-paragraph a:hover {
    color: white;
    text-shadow: 0 0 1px white;
}
