.cboard-section {
    padding: 4rem 0;
    box-sizing: border-box;
    background-color: #f1ecea;
}

.cboard-section-title {
    position: relative;
    text-align: center;
    font-size: 2rem;
    width: fit-content;
    margin: 0 auto;
}

.cboard-section-title::after {
    content: "";
    position: absolute;
    bottom: -25%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--yellow);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}


.cboard-container {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    box-sizing: border-box;
}

.cboard-card {
    position: relative;
    width: 20rem;
    padding: 0.5rem 0.5rem;
    box-sizing: border-box;
    text-decoration: none;
    color: var(--black);
    border: 2px dashed var(--black);
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    transition: all 0.3s;
}

.cboard-card img {
    width: 100px;
    border-radius: 5px;
    background-color: white;
}

.cboard-card h1 {
    font-size: 1.1rem;
    font-weight: 600;
}

.cboard-card h2 {
    font-size: 0.9rem;
    font-weight: 550;
    color: #4b4b4b;
}

.cboard-card:hover {
    transform: scale(1.05);
    background-color: var(--yellow);
    box-shadow: 0 0 5px var(--yellow);
}


/* .rush-card {
    background-color: var(--yellow);
}

.acheesyment-card {
    background-color: yellow;
}


.culture-card {
    background-color: var(--red);
}

.philanthropy-card {
    background-color: #333333;
}

.advocacy-card {
    background-color: #1b1b1b;
}


.dance-card {
    background-color: #eb8f9a;
}

.sports-card {
    background-color: var(--blue);
}

.fundraising-card {
    background-color: blue;
}

.multimedia-card {
    background-color: red;
}

.masscom-card {
    background-color: orange;
}

.webmaster-card {
    background-color: gold;
} */