.eboard-section {
    padding: 4rem 0;
    box-sizing: border-box;
    background-color: #f5f2f0;
}

.eboard-section-title {
    position: relative;
    text-align: center;
    font-size: 2rem;
    width: fit-content;
    margin: 0 auto;
}

.eboard-section-title::after {
    content: "";
    position: absolute;
    bottom: -25%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--yellow);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}


.eboard-container {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem 2rem;
    box-sizing: border-box;
}

.eboard-card {
    position: relative;
    width: 20rem;
    padding: 0.5rem 0.5rem;
    box-sizing: border-box;
    text-decoration: none;
    color: var(--black);
    border: 2px dashed var(--black);
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    transition: all 0.3s;
}

.eboard-card img {
    width: 100px;
    border-radius: 5px;
    background-color: white;
}

.eboard-card h1 {
    font-size: 1.1rem;
    font-weight: 600;
}

.eboard-card h2 {
    font-size: 0.9rem;
    font-weight: 550;
    color: #4b4b4b;
}

.eboard-card:hover {
    transform: scale(1.05);
    background-color: var(--yellow);
    box-shadow: 0 0 5px var(--yellow);
}