/* Header */
#header {
  /* position: -webkit-sticky;
  position: sticky; */
  position: relative;
  top: 0;
  width: 100%;
  height: fit-content;
  z-index: 9999;
}

#background {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--blue);
  transition: all 0.3s;
}

#background::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-image: url(../../assets/textures/grain.png);
}

#divider {
  height: 0.25rem;
  width: 100%;
  background-color: var(--yellow);
  position: absolute;
  bottom: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}




/* Navbar */
.navbar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin: 0 auto;
  text-shadow: 0px 2px 5px black;
  z-index: 1;
}

/* All Links */
.navbar-link {
  text-decoration: none;
  color: white;
  font-weight: 500;
  padding: 0.5rem;
  transition: all 0.3s;
}

.navbar-link:hover {
  color: var(--yellow);
  transform: scale(1.05);
}


/* List of main links */
.navbar-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  width: 20rem;
}

/* Main links */
.navbar-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
}


/* Logo */
.navbar-logo {
  margin: 0 1rem;
  transition: all 0.3s;
}

.navbar-logo:hover {
  transform: scale(1.05);
}

#logo {
  transition: all 0.3s;
}


/* List of sublinks */
.navbar-sublist {
  display: none;
  position: absolute;
  top: 100%;
  background-color: var(--blue);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
  padding: 0.5rem;
  min-width: max-content;
  list-style: none;
  z-index: 2;
}

.navbar-dropdown:hover .navbar-sublist {
  display: block;
}

/* Sub links */
.navbar-subitem {
  margin: 0.5rem 0;
  padding-right: 1rem;
}

.navbar-subitem:first-child {
  margin-top: 0;
}

.navbar-subitem:last-child {
  margin-bottom: 0;
}




/* Small Devices */
@media screen and (max-width: 768px) {
  /* Header */
  .sd-spacer {
    position: relative;
    width: 100%;
    height: 4.25rem;
  }
  #header {
    position: fixed;
  }

  /* Closed navbar */
  .navbar {
    position: relative;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.2rem;
    text-shadow: 0px 2px 5px rgb(0, 0, 0, 0.5);
  }

  .navbar-list {
    flex-direction: column;
    width: 100%;
    align-items: center;
    display: flex;
    max-height: 0;
    opacity: 0.5;
    overflow: hidden;
    text-align: center;
    transition: all 0.3s;
  }

  
  /* Open navbar */
  .navbar-list.active {
    opacity: 1;
    max-height: 25rem;
  }
  
  
  /* Sublinks */
  .navbar-dropdown {
    flex-direction: column;
  }

  .navbar-sublist {
    display: block;
    max-height: 0;
    overflow: hidden;

    position: relative;
    background-color: transparent;
    box-shadow: 0 0 0px rgba(0, 0, 0, 1);
    padding: 0rem;
    transition: all 0.2s ease-in-out;
  }

  .navbar-subitem {
    padding: 0;
    font-style: italic;
    font-size: 1rem;
  }

  .navbar-subitem:last-child {
    margin-bottom: 1rem;
  }
  
  .navbar-dropdown:hover .navbar-sublist {
    max-height: 8rem;
  }

  .navbar-link:hover {
    transform: scale(1);
  }



  /* Menu button */
  .navbar-menu-toggle {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  /* Burger icon lines */
  .navbar-menu-icon {
    width: 30px;
    height: 2px;
    background-color: var(--yellow);
    transition: all 0.3s ease;
  }

  .navbar-menu-icon::before,
  .navbar-menu-icon::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--yellow);
    position: absolute;
    transition: all 0.1s ease;
  }
  
  .navbar-menu-icon::before {
    transform: translateY(-10px);
  }
  
  .navbar-menu-icon::after {
    transform: translateY(10px);
  }


  /* Active burger icon */
  .navbar-menu-icon.active {
    background-color: transparent;
  }
  
  .navbar-menu-icon.active::before {
    transform: rotate(-45deg);
  }
  
  .navbar-menu-icon.active::after {
    transform: rotate(45deg);
  }
}
