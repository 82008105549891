/* Pillar Activities Section */
.pillar-regular-activities-section {
    position: relative;
    width: 100%;
    padding: 4rem 0rem;
    background-color: var(--white);
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
}


.pillar-regular-activities-container {
    position: relative;
    top: 0;
    margin: 0 auto;
    width: 800px;
    max-width: 90%;
    text-align: left;
}

.pillar-regular-activities-title {
    margin: 0 auto;
    position: relative;
    width: fit-content;
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 2rem;
}

/* .pillar-regular-activities-title:after {
    content: "";
    position: absolute;
    bottom: -25%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--yellow);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
} */
