/* Pillars Section */
.pillars-section {
    position: relative;
    background-color: var(--black);
    padding: 1rem;
    padding-bottom: 2rem;
    box-sizing: border-box;

    width: fit-content;
    max-width: 98%;
    margin: 2rem auto;
    border-radius: 20px;
}

.pillars-section-title {
    position: relative;
    color: white;
    text-align: center;
    font-weight: 200;

    font-size: 1.5rem;
    font-style: italic;
}


/* Pillars Container */
.pillars-container {
    position: relative;
    width: 1040px;
    max-width: 100%;
    height: fit-content;
    box-sizing: border-box;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
}


/* Pillar */
.pillar {
    box-sizing: border-box;
    position: relative;
    width: 480px;
    height: 90px;

    text-decoration: none;
    text-align: center;

    padding: 0rem 1rem;
    border-radius: 10px;

    box-shadow: 2px 2px 5px black;
    transition: all 0.3s;
    color: white;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pillar-title {
    font-size: 1.8rem;
}

.pillar-logo {
    width: 75px;
    height: 75px;
    margin-right: 1rem;
    position: relative;
}

.pillar-arrow {
    position: absolute;
    right: 2rem;
}


/* Hover Effect */
.pillar:hover {
    transform: scale(1.03);
    background-color: white !important;
    box-shadow: 0 0 25px white;
}

.dance-pillar {
    color: var(--black);
}

.dance-pillar:hover {
    color: black;
}

.culture-pillar:hover {
    color: var(--red);
}

.sports-pillar:hover {
    color: var(--black);
}

.philanthropy-pillar:hover {
    color: #333333;
}


@media screen and (max-width: 768px) {
    .pillar-title {
        font-size: 1.4rem;
    }

    .pillar-logo {
        width: 75px;
        height: 75px;
        margin-right: 0.75rem;
        position: relative;
    }
}
