/* Container */
.fsaPlus-title-container {
    position: relative;
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    text-align: center;
}


/* Title */
.fsaPlus-title {
    position: relative;
    width: 320px;
    height: 180px;
    margin: 0 auto;
    text-align: center;
}

/* Title Text */
.fsaPlus-title h1 {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0;
    color: #1e1e1e;
    font-size: 4rem;
    font-weight: 500;
    letter-spacing: 1rem;
}

/* Plus Sign */
.fsaPlus-title h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0, -50%);
    margin: 0 5rem;
    color: #4e4e4e;
    font-size: 4rem;
    font-weight: 600;
}
