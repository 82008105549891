.shop-main {
    background-color: #f5f2f0;
}


.shop-sub {
    width: 100%;

    text-align: center;
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--white);

    margin: 0;
    padding: 2rem 1rem;
    box-sizing: border-box;

    background-color: #f35050;
}

.shop-main p {
    position: relative;
    line-height: 0.2;
    letter-spacing: 0.2rem;
    
}

.shop-description {
    position: relative;
    width: 100%;
    background-color: #f5f2f0;
    box-sizing: border-box;
}

.shop-title {
    position: relative;
    text-align: center;
    font-size: 2rem;
    margin: 0 auto;
}

.shop-title::after {
    content: "";
    position: absolute;
    bottom: -25%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--yellow);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}