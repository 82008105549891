.sports-hero {
    background-color: black;
}

.sports-description {
    position: relative;
    width: 100%;
    min-height: fit-content;
    background-color: var(--white);
    padding: 4rem 0.25rem;
    box-sizing: border-box;
}


/* Calendar */
.sports-calendar__container {
    position: relative;
    width: 800px;
    height: fit-content;
    max-width: 100%;
    margin: 0 auto;
}

.sports-calendar {
    width: 100%;
    height: 600px;
    border: none;
}

.sports-calendar__container a {
    color: var(--yellow);
    text-decoration: none;
    font-style: italic;
    font-weight: 600;
    transition: all 0.1s;
}

.sports-calendar__container a:hover {
    color: white;
    text-shadow: 0 0 1px white;
}

.calendar-button {
    position: relative;
    width: fit-content;
    padding: 0.3rem 0.5rem;
    color: var(--blue);
    background-color: transparent;
    border: 1px solid var(--blue);
    border-radius: 5px;
    font-size: 0.75em;
    font-weight: 500;
    letter-spacing: 0.1em;
    cursor: pointer;
    transition: all 0.3s;
}

.calendar-button:hover {
    color: var(--white);
    background-color: var(--blue);
    transform: scale(1.05);
    box-shadow: 0px 0px 5px var(--blue);
}








.sports-tabs {
    padding: 0 2rem;
    border-bottom: 2px solid var(--black);
    margin: 4rem auto 0;
    width: 48rem;
    max-width: 100%;
    box-sizing: border-box;
}

.sports-tabs .MuiTab-root {
    color: var(--black);
    width: 33%;
    font-weight: 400;
    font-size: 1rem;
}

.sports-tabs .Mui-selected {
    color: var(--blue);
}

.sports-tabs .MuiTabs-indicator {
    background-color: var(--blue);
    height: 4px;
}


@media screen and (max-width: 768px) {
    .sports-calendar__container h1 {
        margin: 1rem auto;
        width: fit-content;
    }

    .sports-tabs {
        padding: 0;
    }
    .sports-tabs .MuiTab-root {
        font-size: 0.85rem;
    }
}
