#section-flag {
    position: relative;
    width: 100vw;
    height: 20vw;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: var(--yellow);
}

.flag-container {
    position: relative;
    margin: 0 auto;
    width: 40%;
    height: 100%;
    box-shadow: 15px 10px 10px rgba(0, 0, 0, 1);
}

.triangle {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: white;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
}

.trapezoid {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
  
.top {
    background-color: var(--blue);
    clip-path: polygon(0 0, 100% 0, 100% 50%, 50% 50%);
}

.bottom {
    background-color: var(--red);
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0 100%);
}
