.maffia-page {
    background-color: #1e1e1e;
}

.maffia-description {
    position: relative;
    width: 100%;
    height: fit-content;
    min-height: 60vh;
    background-color: var(--white);
    padding: 2rem;
    box-sizing: border-box;
}

.maffia-description__container {
    position: relative;
    width: 65ch;
    max-width: 100%;
    margin: auto;
}


/* MAFFIA Video */
.maffia-vid {
    position: relative;
    width: 600px;
    max-width: 100%;
    margin: auto;
    padding: 2rem;
    box-sizing: border-box;
}


/* Apply Button */
.apply-button__container {
    position: relative;
    width: fit-content;
    height: 8rem;
    padding: 2rem;
    margin: auto;
    box-sizing: border-box;
}

.apply-button {
    position: relative;
    width: fit-content;
    padding: 1rem 2rem;
    color: var(--black);
    background-color: transparent;
    border: 3px solid var(--black);
    border-radius: 5px;
    font-size: 1.25em;
    cursor: pointer;
    transition: all 0.3s;
}

.apply-button:hover {
    color: var(--white);
    background-color: var(--black);
    transform: scale(1.05);
    box-shadow: 0px 0px 5px var(--black);
}
