/* Hero */
.pillar-hero {
    position: relative;
    width: 100%;
    min-height: fit-content;
    color: white;
    text-align: center;
}

.pillar-hero__main {
    position: relative;
    width: 100%;
    min-height: fit-content;
    color: white;
    text-align: center;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    box-sizing: border-box;
}

/* .pillar-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/textures/grain.png);
    opacity: 0.0;
    z-index: 0;
} */

.pillar-hero__logo {
    position: relative;
    width: 6.5rem;
    padding: 0 1rem;
}

.pillar-hero__title {
    position: relative;
    font-size: 2.5rem;
}

.pillar-hero a {
    text-decoration: none;
}

.pillar-hero__buttons {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    padding-bottom: 2rem;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.pillar-hero button {
    position: relative;
    padding: 0.5rem 0.75rem;
    color: white;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    cursor: pointer;
    transition: all 0.3s;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
}

.pillar-hero button:hover {
    color: var(--black);
    background-color: white;
    transform: scale(1.05);
    box-shadow: 0px 0px 5px white;
}

