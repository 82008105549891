@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Ghiya Strokes';
  src: url('./assets/fonts/Ghiya\ Strokes\ Reg.ttf');
}

body {
  margin: 0;
  font-family: 'Montserrat';
  font-weight: 400;
  background-color: var(--blue);
}

.title {
  font-family: 'Ghiya Strokes';
  font-weight: 100;
  letter-spacing: 2px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

:root {
  --blue: hsl(204, 51%, 43%);
  --yellow: hsl(46, 71%, 56%);
  --red: hsl(353, 54%, 56%);
  --black: #2b2b2b;
  --dark-gray: #333333;
  --white: #f5f2f0;
  --pink: #eb8f9a;
}


.vid-container {
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
    overflow: hidden;

    border-radius: 10px;
    /* box-shadow: 0 0 10px black; */
}

.vid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.temp-text-container {
    width: 100%;
    max-width: 90vw;
    padding: 1rem;
    box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .vid-container {
    box-shadow: none;
  }
}