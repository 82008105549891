.dance-hero {
    background-color: white;
}

.dance-hero__text {
    color: black;
}

.dance-hero__button {
    color: black !important;
    border: 1px solid black !important;
}

.dance-hero__button:hover {
    color: white !important;
    background-color: black !important;
    box-shadow: 0px 0px 5px black !important;
}


.dance-tabs {
    padding: 0 2rem;
    border-bottom: 2px solid var(--black);
    margin: 4rem auto 0;
    width: 48rem;
    max-width: 100%;
    box-sizing: border-box;
}

.dance-tabs .MuiTab-root {
    color: var(--black);
    width: 33%;
    font-weight: 400;
    font-size: 1rem;
}

.dance-tabs .Mui-selected {
    color: var(--pink);
}

.dance-tabs .MuiTabs-indicator {
    background-color: var(--pink);
    height: 4px;
}

.not-bold {
    font-weight: normal;
}

.dance-images {
    position: "relative";
    width: 100%;
}

/* adjusts dimensions for mobile screens */
@media screen and (max-width: 768px) {
    /* .dance-images {
        width: 100%;
    } */
    .dance-tabs {
        padding: 0;
    }
    .dance-tabs .MuiTab-root {
        font-size: 0.85rem;
    }
}
