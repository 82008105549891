.fsaplus-page {
    background-color: #eeeeee;
}

/* .fsaplus-page::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    opacity: 0.1;
    background-image: url('../../../assets/textures/grain.png');
} */

.fsaplus-hero__buttons {
    position: relative;
    margin: 0 auto;
    width: fit-content;
    padding-bottom: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.fsaplus-hero__fb-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
}

.fsaplus-hero button {
    position: relative;
    padding: 0.5rem 0.75rem;
    color: white;
    background-color: #4e4e4e;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    cursor: pointer;
    transition: all 0.3s;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
}

.fsaplus-hero button:hover {
    color: #4e4e4e;
    background-color: white;
    transform: scale(1.05);
    box-shadow: 0px 0px 5px white;
}

.fsaplus-hero a {
    text-decoration: none;
}


.fsaplus-description {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #f5f2f0;
}

.fsaplus-study-social {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 4rem 2rem;
    box-sizing: border-box;
    background-color: white;
    text-align: center;
}

.study-social-description {
    width: 35ch;
    text-align: left;
}




.fsaplus-main-resources {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 4rem 2rem;
    box-sizing: border-box;
    background-color: var(--white);
    text-align: center;
}

.fsaplus-main-resources-title {
    position: relative;
    text-align: center;
    font-size: 2rem;
    width: fit-content;
    margin: 0 auto;
}

.fsaplus-main-resources-title::after {
    content: "";
    position: absolute;
    margin: 0 auto;
    bottom: -25%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--yellow);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}

.fsaplus-social-container {
    width: 100%;
    height: fit-content;
    padding-top: 3rem;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem 4rem;
}

.study-social img {
    position: relative;
    width: 320px;
    max-width: 100%;
    transform: rotate(-1deg);
    transition: all 0.3s;
}

.study-social img:hover {
    transform: scale(1.03);
    box-shadow: 0 0 25px var(--yellow);
}

.next-social {
    font-weight: 600;
}



.fsaplus-sticky-container {
    width: 100%;
    height: fit-content;
    padding-top: 2rem;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem 4rem;
}

.fsaplus-sticky-note {
    position: relative;
    background: linear-gradient(#F9EFAF, #F7E98D);
    text-align: center;
    padding: 1rem; 
    width: 320px;
    height: auto;
    min-height: 320px;
    box-sizing: border-box;
    box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

.fsaplus-sticky-note img.sticky-note-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
}

.fsaplus-sticky-note::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    opacity: 0.075;
    background-image: url('../../../assets/textures/grain.png');
}


.fsaplus-other-orgs {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #f5f2f0;
    padding: 4rem 0;

}

.fsaplus-other-orgs__board {
    position: relative;
    width: 95%;
    max-width: 900px;
    margin: 0 auto;
    height: fit-content;
    background-color: rgb(39, 76, 67);
    padding: 4rem 0.5rem;
    box-sizing: border-box;
    border: 5px solid #996633;
}

.fsaplus-other-orgs__board::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    opacity: 0.2;
    background-image: url('../../../assets/textures/grain.png');
}

.fsaplus-other-orgs-title {
    color: white;
    text-align: center;
    padding-bottom: 1rem;
}

.fsaplus-org-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.fsaplus-org-card {
    position: relative;
    width: 150px;
    height: 200px;
    text-align: center;
    background-color: white;
    font-size: 0.75rem;
    color: var(--black);
    text-decoration: none;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 5px black;
    transition: all 0.3s;
}

.fsaplus-org-card img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 2px solid rgb(39, 76, 67);
}

.fsaplus-org-card:hover {
    transform: scale(1.02) translateY(-0.5rem);
    box-shadow: 0 0 25px white;
}