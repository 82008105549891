.pillar-description-section {
    position: relative;
    width: 100%;
    min-height: fit-content;
    color: var(--black);
    text-align: center;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 4rem 1rem;
    text-align: left;
    box-sizing: border-box;
}

.pillar-description-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/textures/grain.png);
    opacity: 0.0;
    z-index: 0;
}

.pillar-description-content {
    position: relative;
    width: 70ch;
    text-align: left;
}
