/* Programs Section */
.programs-section {
    position: relative;
    background-color: var(--black);
    padding: 1rem 2rem;
    padding-bottom: 2rem;
    box-sizing: border-box;

    width: fit-content;
    max-width: 98%;
    margin: 0 auto;
    border-radius: 20px;
}

.programs-section-title {
    position: relative;
    color: white;
    text-align: center;
    font-weight: 200;

    font-size: 1.5rem;
    font-style: italic;
}


/* Programs Container */
.programs-container {
    position: relative;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}


/* Program */
.program {
    box-sizing: border-box;
    position: relative;
    width: 320px;
    height: 200px;

    text-decoration: none;
    text-align: center;

    border-radius: 10px;

    box-shadow: 2px 2px 5px black;
    transition: all 0.3s;
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.program-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scale(0.9);
}


.program-text {
    position: relative;
    text-align: center;
    margin-top: -1rem;
    font-size: 0.9rem;
    font-style: italic;
}

.arrow-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.1rem;
    padding-left: 0.2rem;
  }
  


/* Hover Effect */
.program:hover {
    transform: scale(1.03) translateY(-0.5rem);
    background-color: white !important;
    box-shadow: 0 0 25px white;
}

.aka-program:hover {
    color: var(--yellow);
}

.maffia-program:hover {
    color: #1e1e1e;
}
