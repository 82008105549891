.projectLunchbox-section {
    background-color: white;
    width: 100%;
    padding: 4rem 2rem;
    box-sizing: border-box;
}

.podcast-container {
    display: flex;
    justify-content: center;
}

.podcast-vid {
    position: relative;
    width: 720px;
    max-width: 85%;
}

.podcast {
    width: 1200px;
    max-width: 100%;
    height: 400px;

    border: none;
    border-radius: 12px;
}