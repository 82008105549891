footer {
  background-color: var(--blue);
  padding: 3rem;
  color: white;
  text-align: center;
  position: relative;
}

footer::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-image: url(../../assets/textures/grain.png);
}

.footer-content {
  text-align: center;
  color: #fff;
  padding: 1rem;
}

.social-icons, .social-icons a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 1rem 0.75rem;
  font-size: 2rem;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.social-icons a:hover {
  color: var(--yellow);
  transform: scale(1.05);
}
