/* About Section */
.about-section {
    position: relative;
    width: 100%;
    padding-top: 12rem;
    padding-bottom: 8rem;
    background-color: var(--red);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    gap: 8rem;
}

.about-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../../assets/textures/grain.png);
    opacity: 0.1;
}


/* About Text */
.about-container {
    position: relative;
    top: 0;
    width: 60ch;
    max-width: 85%;
    text-align: left;
    color: black;
}

.about-title {
    margin: -2rem 0;
    position: relative;
    width: fit-content;
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 2rem;
}

.about-title:after {
    content: "";
    position: absolute;
    bottom: -25%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--yellow);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}

.about-text__intro {
    font-size: 1.1rem;
    font-weight: 500;
}

.about-text__outro {
    font-weight: 500;
    font-style: italic;
}


/* About Video */
.about-vid {
    position: relative;
    width: 480px;
    max-width: 85%;
}

@media screen and (max-width: 768px) {
    .about-section {
        gap: 4rem;
    }
}


/* .sports-calendar__container a {
    color: var(--yellow);
    text-decoration: none;
    font-style: italic;
    font-weight: 600;
    transition: all 0.1s;
}

.sports-calendar__container a:hover {
    color: white;
    text-shadow: 0 0 1px white;
} */

.about-container a {
    text-decoration: none;
}

.constitution-button {
    position: relative;
    width: fit-content;
    padding: 0.5rem 1rem;
    color: white;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 200;
    letter-spacing: 0.1em;
    cursor: pointer;
    transition: all 0.3s;

    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.constitution-button:hover {
    color: var(--red);
    background-color: white;
    transform: scale(1.05);
    box-shadow: 0px 0px 5px white;
}
