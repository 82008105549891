.hero-section {
    position: relative;
    min-height: fit-content;
    width: 100%;
    color: white;
    text-align: center;
    background-color: var(--blue);
    z-index: 0;
}

.hero-background {
    position: absolute;
    top: 0;
    left: 0; /* Left alignment, ensures image touches website border */
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: url(./background2.JPG);
    background-position: center center;
    background-attachment: fixed;
    background-size: cover; /* Scales the image to cover the container */
    z-index: -1;
}

/* Text box */
.hero-textbox {
    box-sizing: border-box;
    height: fit-content;
    width: fit-content;
    max-width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 6rem;
    padding-bottom: 15rem;
    color: white;
    margin: 0 auto;
}

.hero-subtitle {
    font-size: 50px;
    margin-bottom: 1rem;
}

.hero-title {
    font-size: 58px;
    font-style: italic;
    color: var(--yellow);
    margin-top: 0;
}

.hero-description {
    font-size: 1.25rem;
    margin-top: 6rem;
    margin-bottom: 3rem;
}

.hero-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    width: fit-content;
    margin: 0 auto;
}

.hero-button {
    position: relative;
    width: 11rem;
    padding: 0.75rem;
    color: white;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.2em;
    cursor: pointer;
    transition: all 0.3s;
}

.hero-button-primary {
    background-color: var(--red);
    color: white;
    border: 2px solid var(--red);
}

.hero-button:hover {
    color: var(--blue);
    border: 2px solid white;
    background-color: white;
    transform: scale(1.05);
    box-shadow: 0px 0px 5px white;
}


@media screen and (max-width: 768px) {
    .hero {
        padding: 0;
    }

    .hero-background {
        position: fixed;
        background-attachment: unset;
    }
}
